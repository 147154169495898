import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";
import {getAuth, browserLocalPersistence} from "firebase/auth"
import {signInWithEmailAndPassword} from "@firebase/auth";

const app = initializeApp({
    apiKey:'AIzaSyBAj8kPFqacA6xD628RclmO_i_NnYcD8aE',
    projectId: 'guest-checkin-5c6e5',
    databaseURL: "https://guest-checkin-5c6e5-default-rtdb.europe-west1.firebasedatabase.app/"
});
const db = getDatabase(app)

const auth = getAuth(app)
auth.setPersistence(browserLocalPersistence)

export {app, db, auth}