<template>
  <ion-content class="ion-padding">
    <h1>Login</h1>
    <ion-list>
      <ion-item>
        <ion-label position="floating">Mail</ion-label>
        <ion-input
          v-model="email"
          placeholder="info@example.com"
          type="email"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Password</ion-label>
        <ion-input v-model="password" type="password"></ion-input>
      </ion-item>
    </ion-list>
    <ion-button @click="login">Login</ion-button>
  </ion-content>
</template>

<script setup>
import {
  IonInput,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonList,
  loadingController,
} from "@ionic/vue";
import { auth } from "@/firebase-service";
import { ref } from "vue";
import { signInWithEmailAndPassword } from "@firebase/auth";

const email = ref("");
const password = ref("");

async function login() {
  const loader = await loadingController.create({});
  await loader.present();

  try {
    await signInWithEmailAndPassword(auth, email.value, password.value);
  } catch (e) {
    console.log(e);
  }
  await loader.dismiss();
}
</script>

<style scoped></style>
